import React, { useState, useEffect } from 'react'

import { FileItemType, FileUploadEventDetail, InputChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppActionButton,
  WppButton,
  WppTypography,
  WppFileUpload,
  WppInput,
  WppSideModal,
  WppIconTick,
} from '@platform-ui-kit/components-library-react'

import { useAppContext } from 'app/context'

import styles from './UploadFontModal.module.scss'
import { THEME_BUILDER_API_URL } from '../../../../../../config'
import { removeExtension, removeNonAlphanumericFromEnd, removeStopWords } from '../../../../utils'
import { FONT_STYLES, FONT_WEIGHTS, HINTS } from '../../consts'

export const UploadFontModal: React.FC<{
  isOpen: boolean
  onChange: (isOpen: boolean) => void
  fontType: 'primary' | 'secondary'
}> = ({ isOpen, onChange, fontType }) => {
  const appContext = useAppContext()

  const [files, setFiles] = useState<FileItemType[]>([])
  const [fontName, setFontName] = useState<string>('')

  const handleCloseModal = () => {
    onChange(false)
    setFiles([])
    setFontName('')
  }

  useEffect(() => {
    const firstFileName = files[0]?.name || ''
    setFontName(
      removeNonAlphanumericFromEnd(removeExtension(removeStopWords(firstFileName, [...FONT_WEIGHTS, ...FONT_STYLES]))),
    )
  }, [files])

  const handleUploadFile = () => {
    const formData = new FormData()

    files.forEach(file => formData.append('files', file as Blob))

    formData.set('name', fontName)

    fetch(`${THEME_BUILDER_API_URL}/fonts`, {
      method: 'POST',
      body: formData,
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Failed to upload file')
        }
      })
      .then(json => {
        if (fontType === 'primary') {
          appContext.setCustomFont({
            ...json,
            weights: json.weights.map(String),
            name: fontName,
          })
        }

        if (fontType === 'secondary') {
          appContext.setSecondaryCustomFont({
            ...json,
            weights: json.weights.map(String),
            name: fontName,
          })
        }

        setFiles([])
        setFontName('')

        onChange(false)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleFileChange = (event: CustomEvent<FileUploadEventDetail>) => {
    if (event.detail.value) {
      setFiles(event.detail.value)
    }
  }

  const handleFontNameChange = (event: CustomEvent<InputChangeEventDetail>) => {
    if (event.detail.value) {
      setFontName(event.detail.value)
    }
  }

  const isNameAlreadyTaken =
    fontType === 'primary' ? appContext.secondaryFont?.name === fontName : appContext.customFont?.name === fontName

  return (
    <WppSideModal className={styles.modal} size="m" open={isOpen} onWppSideModalClose={handleCloseModal}>
      <WppTypography slot="header" type="2xl-heading" className={styles.title}>
        Upload fonts
      </WppTypography>
      <div slot="body">
        <div className={styles.familyName}>
          <WppTypography type="s-body" className={styles.description}>
            Your uploaded fonts will be merged into one font family. Please, specify the desired name for this family.
          </WppTypography>
          <WppInput
            required
            labelConfig={{ text: 'Family Name' }}
            value={fontName}
            onWppChange={handleFontNameChange}
            messageType={isNameAlreadyTaken ? 'error' : undefined}
            message={isNameAlreadyTaken ? 'Name already taken' : undefined}
          />
        </div>
        <div className={styles.hints}>
          {HINTS.map(hint => (
            <div className={styles.hint}>
              <WppIconTick className={styles.tick} />
              <WppTypography type="s-body">{hint}</WppTypography>
            </div>
          ))}
        </div>

        <WppFileUpload
          key={files.length}
          className={styles.fileUpload}
          accept={['.otf', '.ttf']}
          onWppChange={handleFileChange}
          value={files}
        />
      </div>
      <div slot="actions" className={styles.actions}>
        <WppActionButton variant="secondary" onClick={handleCloseModal}>
          Cancel
        </WppActionButton>
        <WppButton variant="primary" size="s" onClick={handleUploadFile} disabled={isNameAlreadyTaken}>
          Upload
        </WppButton>
      </div>
    </WppSideModal>
  )
}
