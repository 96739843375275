import React, { Fragment } from 'react'

import {
  WppExpandableCard,
  WppTypography,
  WppTooltip,
  WppIconReset,
  WppActionButton,
  WppSelect,
  WppListItem,
  WppIconBookInformation,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'
import { get, size } from 'lodash'

import { useAppContext } from 'app/context'
import { SketchExample } from 'components/ColorPicker/ColorPicker'
import { FormInput } from 'components/FormInput/FormInput'
import { ColorPalette, CreateTheme } from 'pages/manage-theme/types'
import { addSuffixToPaletteColorFieldName, getColorContrast } from 'pages/manage-theme/utils'

import styles from './PaletteSection.module.scss'

export const PaletteSection: React.FC<ColorPalette> = ({ title, description, fields = [], fieldPath, sections }) => {
  const appContext = useAppContext()

  const formik = useFormikContext<CreateTheme>()
  const handleColorChange = (inputField: string, color: string) => {
    formik.setFieldValue(inputField, color)
  }

  const selectedSection = sections.find(i => i.value === get(formik.values, `${fieldPath}.currSection`))
  const sectionFields = selectedSection ? selectedSection.fields : fields

  return (
    <WppExpandableCard className={styles.paletteSectionWrapper} size="l">
      <WppTypography slot="header">
        <WppTypography type="l-strong">{title}</WppTypography>
      </WppTypography>
      <div>
        <WppTypography type="s-body">{description}</WppTypography>

        {size(sections) > 0 && (
          <Fragment>
            {title === 'DataViz Categorical Palette' || title === 'DataViz Sequential Palette' ? (
              <WppSegmentedControl
                value={get(formik.values, `${fieldPath}.currSection` as string)}
                className={`${styles.sectionSelector} ${styles.segmented}`}
              >
                {sections!.map(section => (
                  <WppSegmentedControlItem
                    value={section.value}
                    key={section.name}
                    onClick={() => handleColorChange(`${fieldPath}.currSection`, section.value)}
                  >
                    {section.name}
                  </WppSegmentedControlItem>
                ))}
              </WppSegmentedControl>
            ) : (
              <WppSelect
                onWppChange={(event: CustomEvent) => handleColorChange(`${fieldPath}.currSection`, event.detail.value)}
                value={get(formik.values, `${fieldPath}.currSection`)}
                className={styles.sectionSelector}
              >
                {sections!.map(section => (
                  <WppListItem value={section.value} label={section.name} key={section.name}>
                    <p slot="label">{section.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            )}
          </Fragment>
        )}

        <div className={styles.paletteTable} style={{ height: '' }}>
          <table>
            <tbody>
              <tr className={styles.tableHeaderRow}>
                <th style={{ width: 105 }}>
                  <div className={styles.tableCell}>
                    <WppTypography type="s-strong">Preview</WppTypography>
                  </div>
                </th>
                <th style={{ width: 160 }}>
                  <div className={styles.tableCell}>
                    <WppTypography type="s-strong">Value HEX</WppTypography>
                  </div>
                </th>
                <th style={{ width: 212 }}>
                  <div className={styles.tableCell}>
                    <WppTypography type="s-strong">Name</WppTypography>
                  </div>
                </th>
                <th style={{ width: 160 }}>
                  <div className={styles.tableCell}>
                    <WppTypography type="s-strong">Contrast :1</WppTypography>
                  </div>
                </th>
                <th style={{ width: 100 }}>
                  <div className={styles.tableCell}>
                    <WppTypography type="s-strong">Actions</WppTypography>
                  </div>
                </th>
              </tr>
              {sectionFields.map(row => {
                const inputName = addSuffixToPaletteColorFieldName(
                  [fieldPath, selectedSection?.fieldPath, row.fieldPath].filter(Boolean).join('.'),
                )
                const inputValue = get(formik.values, inputName, '') as string
                const initialValue = get(formik.initialValues, inputName, '') as string
                const isChanged = inputValue !== initialValue

                return (
                  <tr className={styles.tableRow} key={row.name}>
                    <td style={{ height: '74px' }}>
                      <div className={styles.tableCell}>
                        <SketchExample
                          value={inputValue}
                          onChange={data => handleColorChange(inputName, data)}
                          style={{
                            width: '72px',
                            height: '48px',
                            borderRadius: '4px',
                            border: inputValue && inputValue !== '#ffffff' ? '' : 'solid 1px var(--wpp-grey-color-600)',
                            position: 'absolute',
                            top: '10px',
                          }}
                        />
                      </div>
                    </td>
                    <td style={{ height: '74px' }}>
                      <div className={styles.tableCell}>
                        <FormInput label="" name={inputName} placeholder="Enter Hex" type="text" />
                      </div>
                    </td>
                    <td>
                      <div className={styles.tableCell}>
                        <WppTypography type="s-body">{row.name}</WppTypography>
                        {row.description && (
                          <Fragment>
                            <WppTypography type="s-body">{row.description}</WppTypography>
                          </Fragment>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className={styles.tableCell}>{getColorContrast(inputValue)}</div>
                    </td>

                    <td style={{ textAlign: 'right' }}>
                      {isChanged && (
                        <WppTooltip text="Reset all changes" onClick={() => handleColorChange(inputName, initialValue)}>
                          <WppActionButton variant="secondary">
                            <WppIconReset slot="icon-start" />
                          </WppActionButton>
                        </WppTooltip>
                      )}
                      <WppTooltip text="See usage details" onClick={() => appContext.setUsageDetails(row.usageDetails)}>
                        <WppActionButton variant="secondary">
                          <WppIconBookInformation slot="icon-start" />
                        </WppActionButton>
                      </WppTooltip>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </WppExpandableCard>
  )
}
