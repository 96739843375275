import React, { useState } from 'react'

import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { ActiveThemeCard } from 'pages/home/components/ActiveThemeCard/ActiveThemeCard'
import { CreateNewThemeModal } from 'pages/home/components/CreateNewThemeModal/CreateNewThemeModal'
import { ImportJsonModal } from 'pages/home/components/ImportJsonModal/ImportJsonModal'

import { cards, CARD_ACTIONS, Card } from './consts'
import styles from './HomePage.module.scss'
import { useAppContext } from '../../app/context'

export const HomePage: React.FC = () => {
  const [isImportJsonModalOpen, setIsImportJsonModalOpen] = useState(false)
  const [isCreateNewThemeModalOpen, setIsCreateNewThemeModalOpen] = useState(false)
  const appContext = useAppContext()

  const handleCardClick = (card: Card) => {
    appContext.setSecondaryFont(null)
    appContext.setCustomFont(null)
    appContext.setSecondaryCustomFont(null)

    if (card.action === CARD_ACTIONS.CREATE_THEME) {
      appContext.setSavedCreateTheme(null)
      setIsCreateNewThemeModalOpen(true)
    }
    if (card.action === CARD_ACTIONS.IMPORT_JSON_FILE) setIsImportJsonModalOpen(true)
  }

  return (
    <div className={styles.homePage}>
      <ActiveThemeCard />
      {cards.map(card => (
        <WppCard interactive className={styles.actionCard} onClick={() => handleCardClick(card)} key={card.title}>
          <div className={styles.body}>
            <div className={styles.iconWrapper}>{card.icon}</div>
            <WppTypography className={styles.title} type="l-strong">
              {card.title}
            </WppTypography>
            <WppTypography className={styles.description} type="s-body">
              {card.description}
            </WppTypography>
          </div>
        </WppCard>
      ))}
      <ImportJsonModal isOpen={isImportJsonModalOpen} onChange={isOpen => setIsImportJsonModalOpen(isOpen)} />
      <CreateNewThemeModal
        isOpen={isCreateNewThemeModalOpen}
        onChange={isOpen => setIsCreateNewThemeModalOpen(isOpen)}
      />
    </div>
  )
}
