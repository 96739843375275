import React from 'react'

import { createTheme } from '@platform-ui-kit/components-library/dist/platform-ui-kit/utils/theme'
import {
  WppButton,
  WppActionButton,
  WppInput,
  WppTypography,
  WppFilterButton,
  WppPillGroup,
  WppPill,
  WppIconPlus,
  WppBreadcrumb,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSelect,
  WppListItem,
  WppDatepicker,
  WppIconFavorites,
  WppIconLike,
  WppIconExtension,
  WppIconPremium,
  WppTabs,
  WppTab,
} from '@platform-ui-kit/components-library-react'

import { useAppContext } from 'app/context'
import { getThemeFromCreateThemeInfo } from 'pages/manage-theme/utils'

import styles from './TypographyPreview.module.scss'

const items = [
  {
    label: 'Page name',
    path: '',
  },
  {
    label: 'Page name',
    path: '',
  },
  {
    label: 'Current Page Title',
    path: '',
  },
]

export const TypographyPreview: React.FC = () => {
  const appContext = useAppContext()
  const theme = createTheme(getThemeFromCreateThemeInfo(appContext))

  return (
    <div style={theme}>
      <WppTypography className={styles.titlePreviewSection} type="2xs-strong">
        Typography
      </WppTypography>
      <WppBreadcrumb items={items} className={styles.breadcrumb} />
      <div className={styles.titleDescriptionWrapper}>
        <div className={styles.titleAndActions}>
          <WppTypography type="3xl-heading" className={styles.title}>
            Page Title
          </WppTypography>
          <div className={styles.actions}>
            <WppActionButton className={styles.quaternaryBtn}>
              Quaternary
              <WppIconPlus slot="icon-start" />
            </WppActionButton>
            <WppButton size="m">
              Primary
              <WppIconPlus slot="icon-start" />
            </WppButton>
          </div>
        </div>
        <WppTypography type="s-body">Description of the page</WppTypography>
      </div>
      <WppTabs value="1" size="m" className={styles.tabs}>
        <WppTab value="1" counter={2}>
          Text
        </WppTab>
        <WppTab value="2" counter={2}>
          Text
        </WppTab>
        <WppTab value="3" counter={2}>
          Text
        </WppTab>
        <WppTab value="4" counter={2}>
          Text
        </WppTab>
        <WppTab value="5" counter={2}>
          Text
        </WppTab>
      </WppTabs>
      <div className={styles.inputSegmentedWrapper}>
        <WppInput type="search" placeholder="Search" className={styles.inputSearch} />
        <WppSegmentedControl value="1" size="s">
          <WppSegmentedControlItem value="1">Text</WppSegmentedControlItem>
          <WppSegmentedControlItem value="2">Text</WppSegmentedControlItem>
        </WppSegmentedControl>
        <WppFilterButton>Filters</WppFilterButton>
      </div>
      <div className={styles.dropdowns}>
        <WppSelect
          value={1}
          messageType="warning"
          message="Warning message"
          labelConfig={{ text: 'Label' }}
          className={styles.singleSelect}
          required
        >
          <WppListItem value="1">
            <p slot="label">Text</p>
          </WppListItem>
          <WppListItem value="2">
            <p slot="label">Text</p>
          </WppListItem>
        </WppSelect>
        <WppDatepicker
          range
          labelConfig={{ text: 'Label' }}
          messageType="error"
          message="Error message"
          className={styles.datepicker}
          required
        />
      </div>
      <WppPillGroup type="multiple" value={['item-a', 'item-b', 'item-c', 'item-d']} className={styles.pillGroup}>
        <WppPill label="Item A" value="item-a">
          <WppIconFavorites slot="icon-start" />
        </WppPill>
        <WppPill label="Item B" value="item-b">
          <WppIconLike slot="icon-start" />
        </WppPill>
        <WppPill label="Item C" value="item-c">
          <WppIconExtension slot="icon-start" />
        </WppPill>
        <WppPill label="Item C" value="item-d">
          <WppIconPremium slot="icon-start" />
        </WppPill>
      </WppPillGroup>
    </div>
  )
}
