import {
  WppActionButton,
  WppExpandableCard,
  WppCard,
  WppIconFullscreen,
  WppTooltip,
  WppIconReset,
  WppIconBookInformation,
} from '@platform-ui-kit/components-library-react'
import { useFormikContext } from 'formik'
import { get, isEqual } from 'lodash'

import { useAppContext } from 'app/context'
import { FormInput } from 'components/FormInput/FormInput'
import { CreateTheme, UsageDetails } from 'pages/manage-theme/types'

import styles from './Radius.module.scss'

export const Radius: React.FC<{ type: string; usageDetails: UsageDetails }> = ({ type, usageDetails }) => {
  const appContext = useAppContext()

  const formik = useFormikContext<CreateTheme>()
  const currEnteredRadius = get(formik, `values.objectStyles.border.radius.${type}`, '')
  const inputName = `objectStyles.border.radius.${type}`

  const handleResetField = (inputField: string) => {
    formik.setFieldValue(inputField, get(formik.initialValues, inputField))
  }

  const isChanged = !isEqual(get(formik.values, inputName), get(formik.initialValues, inputName))

  return (
    <WppExpandableCard header={type.toUpperCase()} size="l" className={styles.item}>
      <div slot="actions">
        {isChanged && (
          <WppTooltip text="Reset all changes" className={styles.resetBtn} onClick={() => handleResetField(inputName)}>
            <WppActionButton variant="secondary">
              <WppIconReset slot="icon-start" />
            </WppActionButton>
          </WppTooltip>
        )}
        <WppTooltip text="See usage details">
          <WppActionButton variant="secondary" onClick={() => appContext.setUsageDetails(usageDetails)}>
            <WppIconBookInformation slot="icon-start" />
          </WppActionButton>
        </WppTooltip>
      </div>
      <div className={styles.body}>
        <WppCard variant="secondary" className={styles.internalCard}>
          <div className={styles.preview} style={{ borderRadius: `${currEnteredRadius}px` }} />
        </WppCard>

        <FormInput name={inputName} label="Radius" placeholder="Enter number" withPixelsNormalization>
          <WppIconFullscreen slot="icon-start" />
        </FormInput>
      </div>
    </WppExpandableCard>
  )
}
