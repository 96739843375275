import React, { useState } from 'react'

import { createTheme } from '@platform-ui-kit/components-library/dist/platform-ui-kit/utils/theme'
import {
  WppActionButton,
  WppBanner,
  WppCard,
  WppIconChevron,
  WppSegmentedControl,
  WppSegmentedControlItem,
} from '@platform-ui-kit/components-library-react'
import { useNavigate } from 'react-router-dom'

import { useAppContext } from 'app/context'
import { ExportAsButton } from 'components/ExportAsButton/ExportAsButton'
import { getThemeFromCreateThemeInfo } from 'pages/manage-theme/utils'
import { pagePreviewTabs } from 'pages/preview/consts'

import styles from './PreviewPage.module.scss'
import { THEME_BUILDER_PREVIEW_URL } from '../../config'

export const PreviewPage: React.FC = () => {
  const [activePreview, setActivePreview] = useState(pagePreviewTabs[0].id)
  const [isToShowBanner, setIsToShowBanner] = useState(true)
  const navigate = useNavigate()
  const appContext = useAppContext()
  const theme = createTheme(getThemeFromCreateThemeInfo(appContext))

  const handleBannerShowStateChange = (event: CustomEvent) => {
    setIsToShowBanner(event.detail.show)
  }

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  return (
    <div className={styles.previewPage} id={activePreview}>
      <WppBanner
        className={styles.banner}
        closable
        type="information"
        show={isToShowBanner}
        onWppClose={handleBannerShowStateChange}
      >
        Changes to your theme are not saved.
        <br />
        In order to save changes, please export the theme as JSON.
      </WppBanner>
      <div className={styles.body}>
        <div className={styles.actionsAndPreviewWrapper}>
          <WppSegmentedControl value={activePreview} size="m" className={styles.segmentedControl}>
            {pagePreviewTabs.map(tab => (
              <WppSegmentedControlItem
                key={`preview-nav-${tab.id}`}
                value={tab.id}
                onClick={() => setActivePreview(tab.id)}
              >
                {tab.title}
              </WppSegmentedControlItem>
            ))}
          </WppSegmentedControl>
          <ExportAsButton />
          <WppActionButton className={styles.backToEditingButton} onClick={handleBackButtonClick} variant="secondary">
            Back to editing
            <WppIconChevron direction="left" slot="icon-start" />
          </WppActionButton>
        </div>
        <WppCard size="l" className={`${styles.previewCard} ${styles[activePreview]}`} id="home-screen-preview">
          <div className={styles.preview} style={theme}>
            <iframe
              key={`${THEME_BUILDER_PREVIEW_URL}/${activePreview}?id=${appContext.previewId}`}
              title={activePreview}
              src={`${THEME_BUILDER_PREVIEW_URL}/${activePreview}?id=${appContext.previewId}`}
              width="100%"
              height="780px"
            />
          </div>
        </WppCard>
      </div>
    </div>
  )
}
